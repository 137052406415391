import type { dashboard, tag, user } from "@fscrypto/domain";
import { useEffect, useState } from "react";
import * as reactSwitchCase from "react-switch-case";
import { DashboardLikes } from "~/features/dashboard-likes";

import type { Profile } from "@fscrypto/domain/profile";
import { useDashboard } from "~/state/machines/dashboard/dashboard.machine";
import MarketingAuthModal from "../app-shell/marketing/marketing-auth-modal";
import { CommentsPanel, CommentsPanelToggleButton } from "../comments";
import { useCurrentUser } from "../current-user";
import { DashboardGridContainer, DashboardGridLayout, DashboardGridPanelSwitch } from "./dashboard-grid";
import DashboardHeader from "./dashboard-header/dashboard-header";
import { DashboardEmbedPanel } from "./dashboard-panel-embed/dashboard-panel-embed";
import { DashboardHeadingPanel } from "./dashboard-panel-heading";
import { DashboardImagePanel } from "./dashboard-panel-image";
import { DashboardTablePanel } from "./dashboard-panel-table";
import { DashboardTextPanel } from "./dashboard-panel-text";
import { DashboardVisualizationPanel } from "./dashboard-panel-visualizations";
import { DashboardParameters } from "./dashboard-parameters";
import { TwitterShare } from "./dashboard-publish/components/twitter-share";
import { DashboardTabs } from "./dashboard-tabs";
import { QuestLink } from "./dashboard-toolbar/quest-link";
import RefreshButton from "./dashboard-toolbar/refresh-button";

const { Case } = reactSwitchCase;

export const PublishedDashboard = ({
  dashboardId,
  owner,
  likeCount,
  likedByMe,
  isOwner,
  dashboardServer,
  contributors,
  questEcosystemProjects,
  dashboardTags,
}: {
  dashboardId: string;
  owner: Profile;
  likeCount: number;
  likedByMe: boolean;
  isOwner: boolean;
  dashboardServer: dashboard.Dashboard;
  contributors: user.User[];
  questEcosystemProjects: tag.Tag[];
  dashboardTags: tag.Tag[];
}) => {
  const { dashboard, isSaving, isReady } = useDashboard(dashboardId);
  const { currentUser } = useCurrentUser();
  const [isCommentsDrawerOpen, setIsCommentsDrawerOpen] = useState<boolean>(false);

  const isEditable = false;

  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  // this shows the auth modal after 10 seconds if the user is not logged in
  useEffect(() => {
    if (currentUser) return;
    // Check if the modal has been shown before
    const key = "flipside.lastShowedDashboardAuthModal";
    const hasShownModal = localStorage.getItem(key);

    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setOpenAuthModal(true);
        localStorage.setItem(key, new Date().toISOString());
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [currentUser]);

  return (
    <DashboardGridContainer>
      {(width) => {
        return (
          <div>
            <MarketingAuthModal
              open={openAuthModal}
              redirectBackOnClose={false}
              defaultOpen={false}
              onOpenChange={onOpenAuthModalChange}
              persona="viewer"
              origin="dashboard-view"
            />
            <DashboardHeader
              dashboard={isReady ? dashboard : dashboardServer}
              isEditable={isEditable}
              owner={owner}
              isSaving={isSaving}
              contributors={contributors}
              rightSlot={
                <div className="flex w-full flex-col items-center justify-end md:flex-row">
                  <div className="w-full"></div>
                  <QuestLink
                    questEcosystemProjects={questEcosystemProjects}
                    dashboardTags={dashboardTags}
                    dashboard={dashboardServer}
                  />

                  <div className="flex flex-row items-center">
                    <CommentsPanelToggleButton
                      className="mr-4"
                      onClick={() => setIsCommentsDrawerOpen(true)}
                      resourceId={dashboardId}
                      resourceType="dashboard"
                    />
                    <DashboardLikes
                      initialLikeCount={likeCount}
                      initiallyLikedByMe={likedByMe}
                      dashboardId={isReady ? dashboard.id : dashboardServer.id}
                      variant={"published"}
                    />
                    <span className="mx-2" />
                    <RefreshButton dashboardId={isReady ? dashboard.id : dashboardServer.id} isOwner={isOwner} />
                    <TwitterShare owner={owner} dashboardId={dashboardId} />
                  </div>
                </div>
              }
            />
            <CommentsPanel
              isOpen={isCommentsDrawerOpen}
              onClose={() => setIsCommentsDrawerOpen(false)}
              resourceId={dashboardId}
              resourceType="dashboard"
            />
            {isReady && (
              <>
                <DashboardParameters dashboardId={dashboardId} />
                <DashboardTabs variant={"published"} dashboardId={dashboardId} />
                <DashboardGridLayout dashboardId={dashboardId} width={width} variant="published">
                  {(cell) => {
                    return (
                      <DashboardGridPanelSwitch cell={cell}>
                        <Case value="QueryVisual">
                          <DashboardVisualizationPanel cell={cell} isEditable={isEditable} />
                        </Case>
                        <Case value="QueryTable">
                          <DashboardTablePanel cell={cell} isEditable={isEditable} />
                        </Case>
                        <Case value="Image">
                          <DashboardImagePanel cell={cell} isEditable={false} />
                        </Case>
                        <Case value="Text">
                          <DashboardTextPanel cell={cell} isEditable={false} />
                        </Case>
                        <Case value="Heading">
                          <DashboardHeadingPanel cell={cell} />
                        </Case>
                        <Case value="Embed">
                          <DashboardEmbedPanel cell={cell} />
                        </Case>
                      </DashboardGridPanelSwitch>
                    );
                  }}
                </DashboardGridLayout>
              </>
            )}
          </div>
        );
      }}
    </DashboardGridContainer>
  );
};
