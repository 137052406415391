import type { tag, user } from "@fscrypto/domain";
import type { Comment, CommentFlag } from "@fscrypto/domain/comments";
import type { Dashboard } from "@fscrypto/domain/dashboard";
import type { Profile, ProfilePublic } from "@fscrypto/domain/profile";
import { Alert, AlertDescription, AlertTitle } from "@fscrypto/ui";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { Link, useSearchParams } from "@remix-run/react";
import { useEffect } from "react";
import { $path } from "remix-routes";
import invariant from "tiny-invariant";
import { redis } from "~/connections/redis.server";
import { useComments, useFlags } from "~/features/comments";
import { loader as commentsLoader } from "~/features/comments/data/loader.server";
import { PublishedDashboard } from "~/features/dashboard";
import { dashboardFactory } from "~/features/studio-2/dashboard/state/dashboard";
import {
  DashboardWidthContainer,
  RootCellContent,
} from "~/features/studio-2/dashboard/ui/cells/root-cell/root-cell-content";
import { json, redirect, useLoaderData } from "~/remix";
import { services } from "~/services/services.server";
import { usePublicProfiles } from "~/state/entities/profile";
import { createDashboardState } from "~/state/machines/dashboard/dashboard.machine";
import { ddAddTraceRoute } from "~/utils/datadog.server";
import { env } from "~/utils/env.server";
import { formatBlockchainProjectsForMeta } from "~/utils/helpers";
import { notFound } from "~/utils/response.server";
import { buildMetaTags } from "@fscrypto/domain";

export const meta: MetaFunction<typeof loader> = (args) => {
  const dashboard = args.data.dashboard;
  const owner = args.data.owner as Profile;
  const projects = dashboard.tags.filter((tag: tag.Tag) => tag.type === "project");
  const description = dashboard?.description
    ? dashboard.description
    : `Explore insights on ${dashboard.title} from ${owner.slug} on Flipside. ${formatBlockchainProjectsForMeta(projects)}`;
  const url = dashboard && `${args.data.baseUrl}/${args.data.owner.slug}/${dashboard.latestSlug}`;
  const imgUrl =
    args.data?.image?.url ||
    "https://res.cloudinary.com/dsr37ut2z/image/upload/v1731516805/assets/og-images/Insights_1.png";

  return buildMetaTags({
    title: `${dashboard?.title} | ${args.data.owner.name} | Flipside`,
    description: description,
    canonicalUrl: url,
    ogImageUrl: imgUrl,
    twitterImageUrl: imgUrl,
  });
};

const getContributors = async (dashboardId: string): Promise<user.User[]> => {
  const cacheKey = `dashboards:${dashboardId}:contributors`;
  const result = await redis.get(cacheKey);

  if (result) {
    return JSON.parse(result) as user.User[];
  } else {
    const users = await services.dashboards.getContributors(dashboardId);
    await redis.set(cacheKey, JSON.stringify(users), "EX", 60 * 15); // 15 minutes
    return users;
  }
};

const getActiveQuestEcosystemsAsProjects = async (): Promise<tag.Tag[]> => {
  const cacheKey = "quests:ecosystems:projects";
  const result = await redis.get(cacheKey);

  if (result) {
    return JSON.parse(result) as tag.Tag[];
  } else {
    const ecosystems = await services.quest.getActiveEcosystems();
    const tags = await services.tags.findProjectTags();
    const projects = tags.filter((tag) =>
      ecosystems.some((ecosystem) => ecosystem.toLowerCase() === tag.name.toLowerCase()),
    );
    await redis.set(cacheKey, JSON.stringify(projects), "EX", 60 * 15); // 15 minutes
    return projects;
  }
};

export const loader: LoaderFunction = async ({ request, params }) => {
  ddAddTraceRoute("/$owner/$dashboardSlug");
  invariant(params.dashboardSlug, "slug is required");
  const currentUser = await services.auth.getCurrentUser(request);
  let showIsPrivateMessage = false;

  // retrieve slug id from full slug
  const slugId: string = params.dashboardSlug.substring(params.dashboardSlug.length - 6);

  // retrieve dashboard from database
  const dashboard = await services.dashboards.findBySlug(slugId);

  // render not found if dashboard doesn't exist
  if (!dashboard) {
    throw notFound(`cannot find dashboard ${params.dashboardSlug}`);
  }

  // retrieve user from url for redirect based on username history
  const ownerSlug = params.owner;
  invariant(ownerSlug, "owner is required");
  const profile = await services.profiles.findBySlug(ownerSlug, { eager: true });
  if (!profile) {
    const p = await services.profiles.findBySlugHistoryRecord(ownerSlug);
    if (!p) throw notFound("profile not found");
    throw redirect($path("/:owner/:dashboardSlug", { owner: p.name!, dashboardSlug: dashboard.latestSlug }));
  }

  const [likeCount, likedByMe, image, questEcosystemProjects, dashboardTags] = await Promise.all([
    services.likes.getLikeCountForDashboard(dashboard.id),
    currentUser ? services.likes.getIsDashboardLikedByUser(dashboard.id, currentUser?.id) : Promise.resolve(false),
    dashboard.openGraphImageId ? services.fileUploads.find(dashboard.openGraphImageId) : Promise.resolve(),
    getActiveQuestEcosystemsAsProjects(),
    services.tags.findTagsByDashboard(dashboard.id),
  ]);

  const contributors = await getContributors(dashboard.id);
  // if dashboard is not published and user is owner, redirect to edit page, otherwise render not found
  if ((dashboard.version == "2" && !dashboard.published) || (dashboard.version == "3" && !dashboard.publishedAt)) {
    if (currentUser && (await services.accessControl.canCurrentUserSafe(currentUser, "write", dashboard))) {
      throw redirect($path("/studio/dashboards/:id", { id: dashboard.id }));
    } else {
      throw notFound(`cannot find dashboard ${params.dashboardSlug}`);
    }
  }

  // if dashboard is private and user is not allowed to read, render not found
  if (dashboard.visibility === "private") {
    const canRead = currentUser && (await services.accessControl.canCurrentUserSafe(currentUser, "read", dashboard));
    if (!canRead) {
      showIsPrivateMessage = true;
    }
  }

  const baseUrl = env.BASE_URL as string;
  const isOwner = currentUser && currentUser.currentProfileId === dashboard.profileId;

  const { comments, commentProfiles, commentFlags } = await commentsLoader(
    currentUser?.id,
    currentUser?.currentProfileId,
    dashboard.id,
  );

  return json({
    dashboard,
    owner: profile,
    image,
    baseUrl,
    likeCount,
    likedByMe,
    isOwner,
    contributors,
    showIsPrivateMessage,
    questEcosystemProjects,
    dashboardTags,
    comments,
    commentFlags,
    commentProfiles,
  });
};

const Dash = () => {
  const {
    showIsPrivateMessage,
    dashboard,
    owner,
    likeCount,
    isOwner,
    likedByMe,
    contributors,
    questEcosystemProjects,
    dashboardTags,
    comments,
    commentFlags,
    commentProfiles,
  } = useLoaderData<{
    dashboard: Dashboard;
    owner: Profile;
    likeCount: number;
    isOwner: boolean;
    likedByMe: boolean;
    contributors: user.User[];
    showIsPrivateMessage: boolean;
    questEcosystemProjects: tag.Tag[];
    dashboardTags: tag.Tag[];
    comments: Comment[];
    commentFlags: CommentFlag[];
    commentProfiles: ProfilePublic[];
  }>();
  const initialIndex = useInitialTabIndex();
  useEffect(() => {
    // dashboard v2
    createDashboardState(dashboard.id, dashboard, initialIndex, false);
    // dashboardV3
    dashboardFactory.from(
      dashboard,
      owner,
      { likedByMe, likes: likeCount },
      questEcosystemProjects,
      dashboardTags,
      contributors,
    );
  }, [dashboard, initialIndex, owner]);

  if (showIsPrivateMessage) {
    return (
      <Alert className="m-8 w-auto">
        <AlertTitle>Private Content</AlertTitle>
        <AlertDescription>
          This content is private. If you know the owner, please contact them for access. Want to keep your own content
          private?{" "}
          <Link className="underline" to={$path("/pricing")}>
            Upgrade your account
          </Link>
          .
        </AlertDescription>
      </Alert>
    );
  }

  useComments("dashboard", dashboard.id, { data: comments });
  useFlags({ data: commentFlags });
  usePublicProfiles({ data: commentProfiles });

  if (dashboard.version == "3") {
    return (
      <DashboardWidthContainer>
        {(width) => <RootCellContent dashboardId={dashboard.id} dashboardView="published" isMobile={width < 768} />}
      </DashboardWidthContainer>
    );
  }

  return (
    <PublishedDashboard
      likedByMe={likedByMe}
      likeCount={likeCount}
      dashboardId={dashboard.id}
      owner={owner}
      isOwner={isOwner}
      dashboardServer={dashboard}
      contributors={contributors}
      questEcosystemProjects={questEcosystemProjects}
      dashboardTags={dashboardTags}
    />
  );
};

export default Dash;

export const useInitialTabIndex = () => {
  const [search] = useSearchParams();
  const index = search.get("tabIndex");
  return index ? parseInt(index, 10) : 0;
};
