import * as domain from "@fscrypto/domain";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { $path } from "remix-routes";
import { tracking } from "~/utils/tracking";

interface QuestLinkProps {
  questEcosystemProjects: domain.tag.Tag[];
  dashboardTags: domain.tag.Tag[];
  dashboard: domain.dashboard.Dashboard;
}

export const QuestLink = ({ questEcosystemProjects, dashboardTags, dashboard }: QuestLinkProps) => {
  const intersection = questEcosystemProjects.filter((eco) => dashboardTags.some((dash) => dash.id === eco.id));

  const params = { dashboardId: dashboard.id };
  const encoded = Buffer.from(JSON.stringify(params), "binary").toString("base64");

  // base values when no project intersection
  let url = `/earn/?ref=${encoded}`;
  let copy = "Try Quests to earn crypto";
  let questProject: domain.tag.Tag | undefined = undefined;
  let currency: string | undefined = undefined;
  let iconFileName = "flipside.svg";

  // if there is a project intersection, use the first one
  if (intersection.length > 0) {
    const index = Math.floor(Math.random() * intersection.length);
    questProject = intersection[index];
    url = `/earn/${questProject.name}?ref=${encoded}`;
    const eco = domain.quest.questEcosystemSchema.safeParse(questProject!.name);
    currency = eco.success ? domain.quest.questConfigs[eco.data].currency : "crypto";
    copy = `Try Quests to earn ${currency}`;
    if (questProject.iconFileName) iconFileName = questProject.iconFileName;
  }

  return (
    <a
      href={url}
      className="bg-muted text-muted-foreground mb-4 mr-2 flex min-w-[220px] items-center whitespace-nowrap rounded-full p-2 px-3 text-sm font-medium shadow md:mb-0 md:mr-20"
      onClick={() =>
        tracking("dashboard_quest_link_click", "Dashboard", {
          dashboardId: dashboard.id,
          questProjectName: questProject?.name ?? "none",
        })
      }
    >
      <img
        className={clsx("mr-2", { "h-4 w-4": !questProject }, { "h-6 w-6": questProject })}
        alt={copy}
        src={`https://res.cloudinary.com/dsr37ut2z/image/upload/assets/project-icons/${iconFileName}`}
      />
      {copy}
    </a>
  );
};
