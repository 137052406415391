import type { dashboard } from "@fscrypto/domain";
import { QueryRunResult } from "@fscrypto/domain/query-run";
import * as visualizationDomain from "@fscrypto/domain/visualization";
import { Visualization } from "~/features/visualization/ui/charts/chart";
import type { CellWithRef } from "~/state/machines/dashboard/dashboard-grid/util";
import type { VisualizationPanelActorRef } from "~/state/machines/dashboard/dashboard-panel-visualization/dashboard-panel-visualization.machine";
import { useVisualizationPanelMachine } from "~/state/machines/dashboard/dashboard-panel-visualization/dashboard-panel-visualization.machine";
import { AddVisualization } from "./add-visualization";
import { V3VisualizationPanel } from "./v3-visualization-panel";
import { ViewportListener } from "./viewport-listener";
import VisualizationPanelContainer from "./visualization-panel-container";
import { VisLoading } from "./vizLoading";

export type CellWithVizActorRef = dashboard.Cell & { ref: VisualizationPanelActorRef };

export const DashboardVisualizationPanel = ({ cell, isEditable }: { cell: CellWithRef; isEditable: boolean }) => {
  const {
    isLoading,
    isFetchingEphemeralQuery,
    isReady,
    isRefreshing,
    isSelecting,
    data,
    enteredViewport,
    selectItem,
    cancelAdd,
    onEnterViewport,
  } = useVisualizationPanelMachine(cell.ref as VisualizationPanelActorRef);
  return (
    <ViewportListener hasEntered={enteredViewport} onEnterViewport={onEnterViewport}>
      {() => {
        if (isLoading || isReady || isRefreshing) return <VisLoading />;
        if (isSelecting) return <AddVisualization onSelect={(id: string) => selectItem(id)} onCancel={cancelAdd} />;
        const { visualization, visData, owner, query } = data;
        const convertedVisData = {
          jsonData: visData ?? [],
          columns: Object.keys(visData?.[0] ?? {}),
          types: Object.values(visData?.[0] ?? {}),
        };
        if (!visualization) return <VisNotFound />;
        if (visualization.version === "3" && owner && query) {
          return (
            <V3VisualizationPanel
              visualization={visualization as unknown as visualizationDomain.v3.Visualization}
              query={query}
              owner={owner}
              isEditable={isEditable}
              queryRunResult={convertedVisData as unknown as QueryRunResult}
              isFetching={isFetchingEphemeralQuery}
            />
          );
        }
        return (
          <VisualizationPanelContainer
            owner={owner}
            title={visualization?.title ?? ""}
            id={visualization.id}
            isEditable={isEditable}
            query={query}
            type={visualization.chart.type}
          >
            {isFetchingEphemeralQuery ? (
              <VisLoading />
            ) : (
              <Visualization id={visualization.id} data={visData || []} context="dashboard" />
            )}
          </VisualizationPanelContainer>
        );
      }}
    </ViewportListener>
  );
};

export const VisNotFound = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="text-sm text-gray-500">Visualization not found</div>
    </div>
  );
};

export const ErrorFetchingData = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="text-sm text-gray-500">Visualization not found</div>
    </div>
  );
};
